<template>
    <div class="team">
        <van-nav-bar
            title="我的团队"
            fixed
            left-arrow
            @click-left="$router.go(-1)"
        />
        <div class="list">
            <div class="item" v-for="u,i in orders" :key="'ui'+i">
                <div class="avatar">
                    <img :src="courseImage(u.avatar)" alt="">
                </div>
                <div class="username">
                    {{u.nickname}}
                </div>
                <van-icon name="arrow" />
            </div>
        </div>
    </div>
</template>
<script>
import cfg from "../config.js";
import { Toast } from 'vant';
export default {
    name: 'Team',
    data(){
        return {
            uid: 0,
            orders: []
        }
    },
    components:{
    },
    mounted(){
        if(this.$route.params.id){
            this.uid = this.$route.params.id;
            console.log(this.uid);
        }
        this.getData();
    },
    methods:{
        courseImage(url){
            if(url){
                return /^http/.test(url) ? url : cfg._ASSETS_HOST + url;
            }else{
                return require("../assets/logo.png");
            }
        },
        getData(){
            // let _this = this;
            this.$http.call(this.$http.api.team, {
                data: {
                    uid: this.uid
                }
            }).then(res=>{
                let resp = res.data;
                console.log(resp);
                if(resp.code=='0'){
                    this.orders = resp.data;
                }else{
                    Toast(resp.msg);
                    if(resp.code == '500'){
                        setTimeout(()=>{
                            this.$router.push({name:'login'});
                        },2000);
                    }
                } 
            },function(res){
                console.log('something goes wrong...');
                console.log(res);
            });
        }
    }
}
</script>
<style scoped lang="less">
.team{
    padding: 50px 0;

    .list {
        .item {
            padding: 5px 10px;
            margin-bottom: 2px;
            background: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .avatar {
                img {
                    height: 50px;
                    border-radius: 50%;
                }
            }
            .username {
                padding-left: 5px;
                text-align: left;
                flex: 1;
            }
            
        }

    }
    
}


</style>
